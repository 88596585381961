import React, { useState } from 'react'

import IconLink from 'components/IconLink'

import { useTranslation } from 'react-i18next'

import './installerFooter.scss'

export default function InstallerFooter({ installer }) {

    const { t } = useTranslation()

    const [ open, setOpen ] = useState(false)

    return (
        <div className={`installerFooter__container ${ open ? 'installerFooter__container--open' : 'installerFooter__container--closed' }`}>

            <button className='installerFooter__expanderButton' type='button' onClick={() => setOpen(open => !open)}>{ installer.businessName }<div><i className='material-icons'>keyboard_arrow_up</i></div></button>

            <div className='installerFooter__address'>{ installer.address } { installer.addressLine2 }<br/>{ installer.city }, { installer.provinceCode } { installer.postal }</div>

            <div className='installerFooter__contact'>

                <IconLink icon='call' href={`tel:${ installer.phone }`}>{ t(`CALL`) }</IconLink>
                <IconLink icon='directions' href={`https://maps.apple.com?daddr=${ installer.address } ${ installer.city } ${ installer.provinceCode } ${ installer.postal }`}>{ t(`DIRECTIONS`) }</IconLink>
                <IconLink icon='sms' href={`sms:${ installer.messagingNumber }`}>{ t(`SMS`) }</IconLink>

            </div>

        </div>
    )

}