import React, { useState, useEffect, useRef, forwardRef } from 'react'

import './accessCode.scss'

import { useAuthentication } from '../../hooks/authentication'
import { useMutation } from '@apollo/react-hooks'
import { VERIFICATION_AUTH } from 'operations/Auth';

// import { useInterval } from 'hooks/interval'

import { useTranslation } from 'react-i18next'

export default function AccessCode({ onChange, userId }) {

    const { t } = useTranslation()

    const inputRef = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ]

    const [ code, setCode ] = useState({
        0: '',
        1: '',
        2: '',
        3: '',
        4: '',
        5: ''
    })
    
    const [ error, setError ] = useState()

    const { setAuthenticated } = useAuthentication()
    // const [ accessCodeAuthentication ] = useMutation(ACCESS_CODE_AUTHENTICATION)
    const [ verificationAuth ] = useMutation(VERIFICATION_AUTH)

    useEffect(() => {
        inputRef[0].current.focus()
    }, [inputRef])

    useEffect(() => {

        const length = Object.keys(code).reduce((length, current) => length += code[current].length > 0 ? 1 : 0, 0)
        if (length < 6) {
            inputRef[length].current.focus()
        }

    }, [code, inputRef])

    useEffect(() => {

        const length = Object.keys(code).reduce((length, current) => length += code[current].length > 0 ? 1 : 0, 0)

        if (length === 6) {

            (async () => {

                let codeString = ''
                Object.keys(code).map(key => codeString = codeString + code[key])

                try {
                    const { data } = await verificationAuth({
                        variables: {
                            payload: {
                                id: userId,
                                verificationCode: codeString
                            }
                        }
                    })

                    localStorage.setItem('accessToken', data.verificationCodeAuth.accessToken)
                    localStorage.setItem('refreshToken', data.verificationCodeAuth.refreshToken)

                    setAuthenticated(true)
                }
                catch ({graphQLErrors}) {
                    console.log(graphQLErrors)
                    setError(graphQLErrors[0].message)
                    setCode({
                        0: '',
                        1: '',
                        2: '',
                        3: '',
                        4: '',
                        5: ''
                    })
                }

            })()

        }

    }, [ code, userId, verificationAuth, setAuthenticated ])

    function handleChange(id, e) {
        setCode(code => ({ ...code, [id]: e.target.value }))
    }

    function handleKeyPress(id, e) {
        if (e.key === 'Backspace') {
            setCode(code => {

                const codeParts = code
                Object.keys(codeParts).map(part => {
                    if (part >= id - 1) return codeParts[part] = ''
                    return null
                })

                return ({
                    ...codeParts
                })
            })
        }
    }

    const ERROR_MESSAGES = {
        INVALID_CODE_PASTED: t('Your verification code must be 6 digits.'),
        USER_NOT_FOUND: t('Sorry, we are having trouble locating a user with that phone number.'),
        USER_INACTIVE: t('Sorry, but this account has been deactivated.'),
        VERIFICATION_CODE_EXPIRED: t('Sorry, this verification code has expired. Please try again.'),
        INVALID_VERIFICATION_CODE: t('Sorry, the verification code you entered is incorrect.')
    }

    // const codeParts = code.split('')

    // const parts = [0, 1, 2, 3, 4, 5]

    return (
        <>
            <div className='accessCodeContainer'>                
                <div className='accessCodeHelperText' >{ t(`Enter your verification code:`) }</div>
                    
                <div className='accessCodeParts'>
                    <AccessCodePartWithRef ref={ inputRef[0] } id={0} value={code[0]} onChange={(e) => handleChange(0, e) } onKeyPress={ (e) => handleKeyPress(0, e) } />
                    <AccessCodePartWithRef ref={ inputRef[1] } id={1} value={code[1]} onChange={(e) => handleChange(1, e) } onKeyPress={ (e) => handleKeyPress(1, e) } />
                    <AccessCodePartWithRef ref={ inputRef[2] } id={2} value={code[2]} onChange={(e) => handleChange(2, e) } onKeyPress={ (e) => handleKeyPress(2, e) } />
                    <AccessCodePartWithRef ref={ inputRef[3] } id={3} value={code[3]} onChange={(e) => handleChange(3, e) } onKeyPress={ (e) => handleKeyPress(3, e) } />
                    <AccessCodePartWithRef ref={ inputRef[4] } id={4} value={code[4]} onChange={(e) => handleChange(4, e) } onKeyPress={ (e) => handleKeyPress(4, e) } />
                    <AccessCodePartWithRef ref={ inputRef[5] } id={5} value={code[5]} onChange={(e) => handleChange(5, e) } onKeyPress={ (e) => handleKeyPress(5, e) } />
                </div>
            </div>

            {
                error && <div className='error'>
                    { ERROR_MESSAGES[error] }
                </div>
            }

            {/* <Clipboard setError={setError} setCode={setCode} /> */}

            {/* {
                clipboard && (
                    <button type='button' className='pasteButton' onClick={() => {
                        const code = clipboard.replace(/[^0-9]/g, '').split('')
                        if (code.length === 6) {
                            setCode({
                                0: code[0],
                                1: code[1],
                                2: code[2],
                                3: code[3],
                                4: code[4],
                                5: code[5]
                            })
                        }
                        else {
                            setError('INVALID_CODE_PASTED')
                        }
                    }}>Paste verification code</button>
                )
            } */}
        </>   
    )

}

function AccessCodePart({ id, onChange, onKeyPress, value }, ref) {
    return <input ref={ ref } value={ value } type='text' className='accessCode__input' name={`codePart.${ id }`} autoComplete='off' inputMode='numeric' maxLength='1' onChange={ (e) => { e.persist(); onChange(e); } } onKeyDown={ (e) => { e.persist(); onKeyPress(e); } }  />
}

const AccessCodePartWithRef = forwardRef(AccessCodePart)

// function Clipboard({ setError, setCode }) {

//     const { t } = useTranslation()

//     const [ clipboard, setClipboard ] = useState()
//     const [ focus, setFocus ] = useState(true)

//     useInterval(() => {
//         (async function() {
//             if (!navigator.clipboard) return null
//             if (focus) {
//                 const text = await navigator.clipboard.readText()
//                 if (text !== clipboard) setClipboard(text)
//             }
//         })()
//     }, 1000)

//     useEffect(() => {
//         window.addEventListener('blur', handleFocus)
//         return () => window.removeEventListener('blur', handleFocus)   
//     })

//     useEffect(() => {
//         window.addEventListener('focus', handleFocus)
//         return () => window.removeEventListener('focus', handleFocus)   
//     })

//     function handleFocus({ type }) {
//         setFocus(type === 'focus' ? true : false)
//     }

//     if (!clipboard || clipboard.replace(/[^0-9]/g, '').length !== 6) return null

//     return (
//         <button type='button' className='pasteButton' onClick={() => {
//             const code = clipboard.replace(/[^0-9]/g, '').split('')
//             if (code.length === 6) {
//                 navigator.clipboard.writeText('')
//                 setCode({
//                     0: code[0],
//                     1: code[1],
//                     2: code[2],
//                     3: code[3],
//                     4: code[4],
//                     5: code[5]
//                 })
//             }
//             else {
//                 setError('INVALID_CODE_PASTED')
//             }
//         }}>{ t(`Paste verification code`) }</button>
//     )

// }