import React from 'react'

import './label.scss'

import { useTranslation } from 'react-i18next'

export default function({ children: status, large }) {

    const { t } = useTranslation()

    const statuses = {
        'APPOINTMENT_REQUEST': t('Pending'),
        'ARRIVAL_PICKUP': t('pickUpStatus', 'Pick up'),
        'ARRIVAL_PICKUP_COMPLETE': t('pickUpStatusComplete', 'Picked up'),
        'ARRIVAL_DROP': t('dropOffStatus', 'Drop off'),
        'ARRIVAL_DROP_COMPLETE': t('dropOffStatusComplete', 'Dropped off'),
        'STANDARD_SERVICE': t('Standard job'),
        'DIAGNOSIS': t('Diagnosis'),
        'IDENTIFIED': t('Identified'),
        'ESTIMATE_SENT': t('New estimate'),
        'CUSTOMER_APPROVED': t('Work approved'),
        'CUSTOMER_DECLINED': t('Work declined'),
        'WORK_STARTED': t('In Progress'),
        'INVOICE_SENT': t('New invoice'),
        'INVOICE_PAID': t('Invoice paid'),
        'DEPARTURE_PICKUP': t('pickUpStatus', 'Pick up'),
        'DEPARTURE_PICKUP_COMPLETE': t('pickUpStatusComplete', 'Picked up'),
        'DEPARTURE_DROP': t('dropOffStatus', 'Drop off'),
        'DEPARTURE_DROP_COMPLETE': t('dropOffStatusComplete', 'Dropped off'),
        'CANCELLED': t('cancelledStatus', 'Cancelled')
    }

    return <div className={`labelPill labelPill--${ status.toLowerCase() } ${ large ? 'labelPill--large' : 'labelPill--small' }`}>{ statuses[status] }</div>
}