import gql from 'graphql-tag'

export const GET_APPOINTMENTS_HISTORY = gql`
    query GetAppointmentsHistory {
        appointmentsHistory {
            id
            appointmentStartAt
            status
            vehicle {
                id
                year
                make
                model
                images {
                    location
                }
            }
            installer {
                id
                businessName
            }
        }
    }
`

export const GET_APPOINTMENTS = gql`
    query GetAppointments($filter: AppointmentFilter $range: AppointmentRange $startDate: String $endDate: String) {
        appointments(filter: $filter range: $range startDate: $startDate endDate: $endDate) {
            id
            appointmentStartAt
            appointmentEndAt
            description
            descriptionCreatedAt
            descriptionUpdatedAt
            status
            isPaid
            mileage
            mileageType
            createdAt
            updatedAt
            vehicle {
                id
                year
                make
                model
                images {
                    location
                }
            }
            installer {
                id
                businessName
                address
                addressLine2
                city
                provinceCode
                postal
                phone
                messagingNumber
            }
        }
    }
`

export const GET_APPOINTMENT_BY_ID = gql`
    query GetAppointmentById($id: ID!) {
        appointment: appointmentById(id: $id) {
            id
            appointmentStartAt
            appointmentEndAt
            description
            descriptionCreatedAt
            descriptionUpdatedAt
            status
            isPaid
            mileage
            mileageType
            workStartAt
            workEndAt
            pickupAndDrop
            createdAt
            updatedAt
            installer {
                id
                businessName
                address
                addressLine2
                city
                provinceCode
                postal
                phone
                messagingNumber
            }
            customer {
                id
                fullName
                phone
                addresses {
                    id
                    address
                    addressLine2
                    city
                    province
                    postal
                    country
                }
            }
            vehicle {
                id
                year
                make
                model
                images {
                    location
                }
            }
            issues {
                id
                userId
                issue
                files
                createdAt
                updatedAt
            }
            estimates {
                id
                description
                files
                currency
                subtotal
                taxCode
                taxRate
                tax
                total
                isApproved
                createdAt
                updatedAt
                salesTaxes {
                    id
                    taxName
                    taxAbbr
                    taxNumber
                    description
                    taxRate
                    isCompoundTax
                    isRecoverable
                    displayTaxNumber
                }
            }
            invoice {
                id
                description
                files
                currency
                subtotal
                taxCode
                taxRate
                tax
                total
                isPaid
                createdAt
                updatedAt
                salesTaxes {
                    id
                    taxName
                    taxAbbr
                    taxNumber
                    description
                    taxRate
                    isCompoundTax
                    isRecoverable
                    displayTaxNumber
                }
            }
        }
    }
`

export const SET_APPOINTMENT_STATUS = gql`
    mutation SetAppointmentStatus($payload: SetAppointmentStatusPayload!) {
        setAppointmentStatus(payload: $payload) {
            id
            status
            pickupAndDrop
        }
    }
`

export const SUBSCRIBE_APPOINTMENT_UPDATED = gql`
    subscription OnAppointmentUpdated($filter: AppointmentSubscriptionFilter!) {
        appointmentUpdated(filter: $filter) {
            id
        }
    }
`