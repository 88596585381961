import React, { useState, useEffect } from 'react'

import { FormatCurrency } from 'components/Format'

import { useTranslation } from 'react-i18next'

export function taxCalculator(subtotal, salesTaxes) {

    const regularSalesTaxes = salesTaxes.filter(salesTax => !salesTax.isCompoundTax)
    const compoundSalesTaxes = salesTaxes.filter(salesTax => salesTax.isCompoundTax)

    const newSalesTaxes = []

    let tax = 0

    regularSalesTaxes && regularSalesTaxes.map(salesTax => {
        const taxAmount = Number(subtotal) * (salesTax.taxRate * 0.01)
        tax += taxAmount
        return newSalesTaxes.push({
            taxName: salesTax.taxName,
            taxAbbr: salesTax.taxAbbr,
            taxRate: salesTax.taxRate,
            taxAmount,
            isCompoundTax: salesTax.isCompoundTax,
            isRecoverable: salesTax.isRecoverable
        })
    })
    
    compoundSalesTaxes && compoundSalesTaxes.map(salesTax => {
        const taxAmount = (Number(subtotal) + tax) * (salesTax.taxRate * 0.01)
        tax += taxAmount
        return newSalesTaxes.push({
            taxName: salesTax.taxName,
            taxAbbr: salesTax.taxAbbr,
            taxRate: salesTax.taxRate,
            taxAmount,
            isCompoundTax: salesTax.isCompoundTax,
            isRecoverable: salesTax.isRecoverable
        })
    })

    return {
        subtotal: Number(subtotal),
        tax,
        salesTaxes: newSalesTaxes,
        total: Number(subtotal) + tax
    }

}

export default function SalesTax({ subtotal, salesTaxes, onChange }) {

    const { t } = useTranslation()

    const [ state, setState ] = useState(taxCalculator(subtotal, salesTaxes))
    useEffect(() => {
        setState(taxCalculator(subtotal, salesTaxes))
        // eslint-disable-next-line
    }, [subtotal])
    useEffect(() => {
        onChange && onChange(state)
        // eslint-disable-next-line
    }, [state])

    console.log(state)

    return (
        <>
            <div className='estimate__subtotal'>{ t(`Subtotal:`) } <FormatCurrency>{ state.subtotal }</FormatCurrency></div>
            {
                state.salesTaxes && state.salesTaxes.map((salesTax, i) => <div key={i} className='estimate__tax'>{ salesTax.taxAbbr } { salesTax.taxRate }%: <FormatCurrency>{ salesTax.taxAmount }</FormatCurrency></div>)
            }
            <div className='estimate__total'>{ t(`Total:`) } $<FormatCurrency>{ state.total }</FormatCurrency></div>
        </>
    )

}