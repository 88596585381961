import React from 'react'
import Layout from 'components/Layout'
import Header from 'components/Header'
import NoData from 'components/NoData'
import Card, { CardImage, CardText, CardLabel } from 'components/Card'

import { useQuery } from '@apollo/react-hooks'
import { GET_APPOINTMENTS } from 'operations/Appointment'
import Button from 'components/Button'
// import { FormatDate } from 'components/Format'
import { Img } from 'components/Tueri'

import './appointment.scss'
// import { useModal } from 'hooks/modal'
import Loader from 'components/Loader'

import { DateTime } from 'luxon'

import { useTranslation } from 'react-i18next'

import IconLink from 'components/IconLink'

export default function Dashboard() {

    const { t } = useTranslation()

    // const { setModal } = useModal()

    const { error, loading, data } = useQuery(GET_APPOINTMENTS, {
        variables: {
            startDate: DateTime.local().startOf('day'),
            endDate: DateTime.local().endOf('day')
        },
        fetchPolicy: 'cache-and-network'
    })

    if (error || loading) return <Loader />

    return (
        <Layout>
            <Header />

            <div className='containerPadding'>

                <div className='constrain'>

                    <h1>{ t('dashboard', `Dashboard`) }</h1>

                    {
                        data.appointments && data.appointments.length > 0 ? (
                            data.appointments.map(appointment => (
                                <Card key={ appointment.id } type='vehicle' className='appointment__container'>
                                    <CardLabel>{ appointment.status }</CardLabel>
                                    <CardImage>
                                        { appointment.vehicle.images && appointment.vehicle.images[0] && <Img aspectRatio={50} src={ appointment.vehicle.images[0].location } alt={ `${ appointment.vehicle.year } ${ appointment.vehicle.make } ${ appointment.vehicle.model }` } /> }
                                        <div className='cardImage_overlay'>
                                            <div>
                                                <div className='appointment__vehicle'>{ appointment.vehicle.year } { appointment.vehicle.make } { appointment.vehicle.model }</div>
                                            </div>
                                        </div>
                                    </CardImage>
                                    <CardText>
                                        <div className='appointment__dateString'>
                                            { appointment.appointmentStartAt && <h1>{ DateTime.fromMillis(Number(appointment.appointmentStartAt)).toLocaleString({ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZoneName: 'short', weekday: 'short' }) }</h1> }
                                        </div>
                                        {/* <Button onClick={ () => setModal({ type: 'APPOINTMENT', id: appointment.id }) }>View appointment</Button> */}
                                        <Button to={`/appointment/${ appointment.id }`}>View appointment</Button>
                                        <div className='appointment__date'>
                                            { appointment.installer && (
                                                    <>
                                                        <h3 className='appointment__installer'>{ appointment.installer.businessName }</h3>
                                                        { appointment.installer.address } { appointment.installer.addressLine2 } { appointment.installer.city }, { appointment.installer.provinceCode } { appointment.installer.postal }
                                                    </>
                                                )
                                            }
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-evenly'
                                            }}
                                        >
                                            <IconLink icon='call' href={`tel:${ appointment.installer.phone }`}>{ t(`CALL`) }</IconLink>
                                            <IconLink icon='directions' href={`https://maps.apple.com?daddr=${ appointment.installer.address } ${ appointment.installer.city } ${ appointment.installer.provinceCode } ${ appointment.installer.postal }`}>{ t(`DIRECTIONS`) }</IconLink>
                                            <IconLink icon='sms' href={`sms:${ appointment.installer.messagingNumber }`}>{ t(`SMS`) }</IconLink>
                                        </div>
                                    </CardText>
                                </Card>
                            ))
                        ) : (
                            <NoData
                                // buttonText={t(`Book an appointment`)}
                            >
                                { t(`Looks like you don't have an appointment yet.`) }
                            </NoData>
                        )
                    }      

                </div>

            </div>      
            
        </Layout>
    )

}