import React from 'react'
import './noData.scss'

export default function NoData({ children, buttonText }) {
    return (
        <div className='noData'>
            { children }
            {
                buttonText && <button className='noData__button' type='button'>{ buttonText }</button>
            }
        </div>
    )
}