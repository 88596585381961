import React, { useState, useEffect } from 'react'
// import Layout from 'components/Layout'
// import Header from 'components/Header'
// import NoData from 'components/NoData'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery, useMutation, useSubscription } from '@apollo/react-hooks'
import { GET_APPOINTMENT_BY_ID, SET_APPOINTMENT_STATUS, SUBSCRIBE_APPOINTMENT_UPDATED } from 'operations/Appointment'
import Card, { CardImage, CardLabel } from 'components/Card'
// import InstallerCard from 'components/InstallerCard'
import { FormatCurrency } from 'components/Format'
import { Img } from 'components/Tueri'
// import Estimate from 'components/Estimate'
import Form, { Submit, Field } from 'components/Form'
import { DateTime } from 'luxon'

import InstallerFooter from 'components/InstallerFooter'
// import VehicleHeader from 'components/VehicleHeader'

// import TabContainer, { TabButton, Tab } from 'components/Tabs'

// import Loader from 'components/Loader'
import './issue.scss'

import { useAuthentication } from 'hooks/authentication'

// import Label from 'components/Label'
import ImageBox from 'components/ImageBox'
import Button from 'components/Button'

import Layout from 'components/Layout'
import Header from 'components/Header'
// import { ModalAsChild as Modal, ModalClose } from 'components/Modal'
// import { useModal } from 'hooks/modal'

import SalesTax from 'components/SalesTax'

import ReactMarkdown from 'react-markdown'

import IconLink from 'components/IconLink'

// STRIPE.JS
import { useStripe, useElements, Elements, CardElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import axios from 'axios'
import validator from 'validator'

import { useTranslation } from 'react-i18next'

import Loader from 'components/Loader'

import AppointmentSection from 'components/AppointmentSection'

export default function Appointment({ display }) {

    const { t } = useTranslation()

    const { user } = useAuthentication()

    const history = useHistory()

    // const { modal: { id: appointmentId } } = useModal()

    const { appointmentId } = useParams()

    const { error, loading, data, refetch } = useQuery(GET_APPOINTMENT_BY_ID, {
        variables: {
            id: appointmentId
        },
        fetchPolicy: 'cache-and-network',
        // pollInterval: 10000
    })

    const [ setAppointmentStatus ] = useMutation(SET_APPOINTMENT_STATUS)
    const { data: subscriptionData, loading: subscriptionLoading } = useSubscription(SUBSCRIBE_APPOINTMENT_UPDATED, { variables: { filter: { customerId: user.cid } } })

    useEffect(() => {
        if (!subscriptionLoading && subscriptionData && subscriptionData.appointmentUpdated && subscriptionData.appointmentUpdated.id) refetch()
        // eslint-disable-next-line
    }, [subscriptionLoading, subscriptionData])

    if (error) return 'Error!'

    if (loading) return <Loader />
    
    const { appointment } = data

    return (
        <Layout>
            <Header />

            <div className='containerPadding' style={{ paddingBottom: '60px' }}>

                <div className='constrain'>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                    <h1>{ t(`Appointment`) }</h1><div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'from-right'
                                                }}
                                            ><IconLink icon='call' small href={`tel:${ appointment.installer.phone }`}>{ t(`CALL`) }</IconLink>
                                                <IconLink icon='directions' small href={`https://maps.apple.com?daddr=${ appointment.installer.address } ${ appointment.installer.city } ${ appointment.installer.provinceCode } ${ appointment.installer.postal }`}>{ t(`DIRECTIONS`) }</IconLink>
                                                <IconLink icon='sms' small href={`sms:${ appointment.installer.messagingNumber }`}>{ t(`SMS`) }</IconLink></div>
                    </div>

                    {
                        loading ? (
                            <Loader />
                        ) : (
                            <>
                                {/* <VehicleHeader vehicle={ appointment.vehicle } installer={ appointment.installer } appointmentStartAt={ appointment.appointmentStartAt } /> */}

                                <Card type='vehicle'>
                                    <CardLabel>{ appointment.status }</CardLabel>
                                    <CardImage>
                                        { appointment.vehicle.images && appointment.vehicle.images[0] && <Img aspectRatio={50} src={ appointment.vehicle.images[0].location } alt={ `${ appointment.vehicle.year } ${ appointment.vehicle.make } ${ appointment.vehicle.model }` } /> }
                                        <div className='cardImage_overlay'>
                                            <div>
                                                <div>
                                                    <div className='appointment__vehicle'>{ appointment.vehicle.year } { appointment.vehicle.make } { appointment.vehicle.model }</div>
                                                </div>
                                                <div className='appointment__dateString'>
                                                    { appointment.appointmentStartAt && <>{ DateTime.fromMillis(Number(appointment.appointmentStartAt)).toLocaleString({ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZoneName: 'short', weekday: 'short' }) }</> }
                                                </div>
                                            </div>
                                        </div>
                                    </CardImage>
                                </Card>

                                {/* <InstallerCard installer={ appointment.installer } /> */}
{/* 
                                <Card type='vehicle'>

                                    <CardText>                                        
                                            <div className='appointment__date' style={{ textAlign: 'center' }}>
                                                { appointment.installer && (
                                                        <>
                                                            <h3 className='appointment__installer'>{ appointment.installer.businessName }</h3>
                                                            { appointment.installer.address } { appointment.installer.addressLine2 } { appointment.installer.city }, { appointment.installer.provinceCode } { appointment.installer.postal }
                                                        </>
                                                    )
                                                }
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly'
                                                }}
                                            >
                                                <IconLink icon='call' href={`tel:${ appointment.installer.phone }`}>{ t(`CALL`) }</IconLink>
                                                <IconLink icon='directions' href={`https://maps.apple.com?daddr=${ appointment.installer.address } ${ appointment.installer.city } ${ appointment.installer.provinceCode } ${ appointment.installer.postal }`}>{ t(`DIRECTIONS`) }</IconLink>
                                                <IconLink icon='sms' href={`sms:${ appointment.installer.messagingNumber }`}>{ t(`SMS`) }</IconLink>
                                            </div>
                                        </CardText>
                                    </Card> */}

                                {
                                    display === 'ESTIMATE' || display === 'INVOICE' || display === 'PAID' ? (
                                        <>
                                            {
                                                display === 'ESTIMATE' && (
                                                    <>
                                                        <h1>{ t(`Estimate`) }</h1>
                                                        {
                                                            appointment.estimates && appointment.estimates.map(estimate => (
                                                                <div key={ estimate.id }>
                                                                    <ImageBox images={ estimate.files } />
                                                                    <SalesTax subtotal={ estimate.subtotal } salesTaxes={ estimate.salesTaxes } />

                                                                    <Button onClick={() => {
                                                                        setAppointmentStatus({
                                                                            variables: {
                                                                                payload: {
                                                                                    appointmentId: appointment.id,
                                                                                    status: 'CUSTOMER_APPROVED'
                                                                                }
                                                                            }
                                                                        })
                                                                        history.push(`/appointment/${ appointment.id }`)
                                                                    }}>{ t(`Approve estimate`) }</Button>

                                                                    <Button className='decline' onClick={() => {
                                                                        setAppointmentStatus({
                                                                            variables: {
                                                                                payload: {
                                                                                    appointmentId: appointment.id,
                                                                                    status: 'CUSTOMER_DECLINED'
                                                                                }
                                                                            }
                                                                        })
                                                                        history.push(`/appointment/${ appointment.id }`)
                                                                    }}>{ t(`Decline estimate`) }</Button>
                                                                </div>
                                                            ))
                                                        }
                                                    </>
                                                )
                                            }

                                            {
                                                display === 'INVOICE' && (
                                                    <>
                                                        <h1>{ t(`Invoice`) }</h1>
                                                        {
                                                            appointment.invoice && (
                                                                <div key={ appointment.invoice.id }>
                                                                    <ImageBox images={ appointment.invoice.files } />
                                                                    <SalesTax subtotal={ appointment.invoice.subtotal } salesTaxes={ appointment.invoice.salesTaxes } />

                                                                    <Payment appointment={ appointment } onSetPage={() => history.push(`/appointment/${ appointment.id }/thank-you`)} />

                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }    

                                            {
                                                display === 'PAID' && (
                                                    <>
                                                        <h1>{ t(`Payment Complete`) }</h1>
                                                        <p>{ t(`Thank you for your payment!`) }</p>
                                                        {
                                                            appointment.status === 'DEPARTURE_PICKUP' && t(`Your vehicle is ready for pickup!`)
                                                        }
                                                        {
                                                            appointment.status === 'DEPARTURE_DROP' && t(`We'll send you a message when we are on our way to drop off your vehicle.`)
                                                        }
                                                    </>
                                                )
                                            }                                
                                        </>
                                    ) : (
                                        <>
                                            {
                                                appointment.estimates && ['ESTIMATE_SENT'].includes(appointment.status) && (
                                                    // <Button onClick={() => setPage('ESTIMATE')}>{ t(`View estimate`) }</Button>
                                                    <Button to={`/appointment/${ appointment.id }/estimate`}>{ t(`View estimate`) }</Button>
                                                )
                                            }

                                            {
                                                appointment.invoice && ['INVOICE_SENT'].includes(appointment.status) && (
                                                    // <Button onClick={() => setPage('INVOICE')}>{ t(`View invoice`) }</Button>
                                                    <Button to={`/appointment/${ appointment.id }/invoice`}>{ t(`View invoice`) }</Button>
                                                )
                                            }

                                            <AppointmentSection title={ t(`Description`) } isOpen={ ['APPOINTMENT_REQUEST','ARRIVAL_PICKUP', 'ARRIVAL_PICKUP_COMPLETE', 'ARRIVAL_DROP', 'ARRIVAL_DROP_COMPLETE', 'STANDARD_SERVICE', 'DIAGNOSIS', 'IDENTIFIED'].includes(appointment.status) }>
                                                <ReactMarkdown>{ appointment.description }</ReactMarkdown>
                                            </AppointmentSection>

                                            {
                                                appointment.issues && appointment.issues.length > 0 && !['APPOINTMENT_REQUEST','ARRIVAL_PICKUP','ARRIVAL_PICKUP_COMPLETE','ARRIVAL_DROP', 'ARRIVAL_DROP_COMPLETE','STANDARD_SERVICE','DIAGNOSIS','IDENTIFIED'].includes(appointment.status) && (
                                                    <AppointmentSection title={ t('numberOfIssues', `{{ count }} Issue`, { count: appointment.issues.length }) } isOpen={ ['ESTIMATE_SENT', 'CUSTOMER_APPROVED', 'CUSTOMER_DECLINED', 'WORK_STARTED'].includes(appointment.status) } >
                                                        {
                                                            appointment.issues.map(issue => (
                                                                <div key={ issue.id }>
                                                                    <div className='issue__text'>{ issue.issue }</div>
                                                                    <ImageBox images={ issue.files } />
                                                                </div>
                                                            ))
                                                        }
                                                    </AppointmentSection>
                                                )
                                            }
                                            {
                                                appointment.estimates && appointment.estimates.length > 0 && (
                                                    <AppointmentSection title={ t(`Estimate`) }>
                                                        {
                                                            appointment.estimates.map((estimate, i) => <ImageBox key={i} images={ estimate.files } />)
                                                        }
                                                    </AppointmentSection>
                                                )
                                            }
                                        </>
                                    )
                                }

                            </>
                        )
                    }

                </div>

            </div>

            <InstallerFooter installer={ appointment.installer } />

        </Layout>
    )

}

function Payment({ appointment, onSetPage }) {

    const [ stripePromise, setStripePromise ] = useState()
    const [ paymentIntentData, setPaymentIntentData ] = useState()

    useEffect(() => {
        axios.get(`${ process.env.NODE_ENV === 'development' ? 'http://localhost:4200' : 'https://autoserviceapi.dsmedia.ca' }/stripe/paymentIntent/${ appointment.invoice.id }`, {
            headers: {
                authorization: `Bearer ${ localStorage.getItem('accessToken') }`
            }
        })
        .then(async (res) => {
            if (res.status === 200) {
                setPaymentIntentData(res.data)

                // TODO: need to look at how to do this better to prevent re-renders
                const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY,
                    { stripeAccount: res.data.stripeAccount }
                )

                setStripePromise(stripePromise)
            }
        })
        // eslint-disable-next-line
    }, [])

    if (!paymentIntentData || !stripePromise) return null

    // console.log(stripePromise)

    return (
        <Elements stripe={ stripePromise }>
            <PaymentForm appointment={ appointment } paymentIntentData={ paymentIntentData } onSetPage={ (e) => onSetPage(e) } />
        </Elements>
    )

}

function PaymentForm({ appointment, paymentIntentData, onSetPage }) {

    const stripe = useStripe()
    const elements = useElements()

    const [ setAppointmentStatus ] = useMutation(SET_APPOINTMENT_STATUS)
    const [ errors, setErrors ] = useState({})

    // const handleSubmit = async (event) => {
    //     event.preventDefault()
    //     if (!stripe || !elements) {
    //         return
    //     }

    //     const result = await stripe.confirmCardPayment(paymentIntentData.clientSecret, {
    //         payment_method: {
    //             card: elements.getElement(CardElement),
    //         }
    //     })

    //     if (result.error) {
    //         console.log(result.error.message)
    //     } else {
    //         if (result.paymentIntent.status === 'succeeded')
    //         console.log('success')
    //     }
    // }
    const CARD_ELEMENT_OPTIONS = {
        // classes
        // style: {
        //   base: {
        //     color: "#32325d",
        //     fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        //     fontSmoothing: "antialiased",
        //     fontSize: "16px",
        //     border: '1px solid red',
        //     "::placeholder": {
        //       color: "#aab7c4",
        //     },
        //   },
        //   invalid: {
        //     color: "#fa755a",
        //     iconColor: "#fa755a",
        //   },
        // },
      };

    return (
        <Form
            onSubmit={ async ({ rawInputs, enableSubmit }) => {

                if (!stripe || !elements) {
                    return
                }

                const formErrors = {}
                if (validator.isEmpty(rawInputs.cardholder)) formErrors.cardholder = true
                if (validator.isEmpty(rawInputs.email) || !validator.isEmail(rawInputs.email)) formErrors.email = true

                if (Object.keys(formErrors).length > 0) {
                    enableSubmit()
                    return setErrors(formErrors)
                }
        
                const result = await stripe.confirmCardPayment(paymentIntentData.clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: rawInputs.cardholder
                        }
                    },
                    receipt_email: rawInputs.email
                })
        
                if (result.error) {
                    alert(result.error.message)
                    enableSubmit()
                    return
                } else {
                    if (result.paymentIntent.status === 'succeeded')
                    console.log('success')
                }

                setAppointmentStatus({
                    variables: {
                        payload: {
                            appointmentId: appointment.id,
                            status: appointment.pickupAndDrop ? 'DEPARTURE_DROP' : 'DEPARTURE_PICKUP'
                        }
                    }
                })
                onSetPage('PAID')

            }}
        >
            {() => (
                <>
                    <h1 style={{ marginTop: '4rem' }}>Payment Information</h1>
                    <Field name='cardholder' label='Name on card' required error={ errors.cardholder } errorMessage='Please enter the cardholder name.' />
                    <Field name='email' type='email' label='Email' helper={`We'll send a receipt here.`} required error={ errors.cardholder } errorMessage='Please enter the cardholder name.' />
                    <CardElement options={ CARD_ELEMENT_OPTIONS } />
                    <br/>
                    <Submit>Pay $<FormatCurrency>{ appointment.invoice.total }</FormatCurrency></Submit>
                </>
            )}
        </Form>
    )
}