import React from 'react'

export default function FieldContainer({ children, isFocused, isFilled, inputId, label, helper, isTextarea, isCheckbox }) {

    return (
        <label htmlFor={ inputId }>
            <div className={`fieldContainer${ isFilled ? ' fieldContainer--filled' : '' }${ isFocused ? ' fieldContainer--focused' : '' }${ isTextarea ? ' fieldContainer--textarea' : '' } ${ isCheckbox ? ' fieldContainer--checkbox' : '' }`}>
                <span className='field__label'>
                    { label }
                </span>
                {
                    helper && <span className='field__helper'>{ helper }</span>
                }
                { children }
            </div>
        </label>
    )

}