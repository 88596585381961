import gql from 'graphql-tag'

export const GET_VEHICLES = gql`
    query Vehicles {
        vehicles {
            id
            year
            make
            model
            trim
            bodyStyle
            chromeTrim
            mileage
            mileageType
            vin
            images {
                location
            }
        }
    }

`