import React from 'react'

import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom'

import Dashboard from 'pages/Dashboard'
import Appointment from 'pages/Appointment'
import Appointments from 'pages/Appointments'
import Vehicles from 'pages/Vehicles'
import Auth from 'pages/Auth'
import Token from 'pages/Token'

import { useModal } from 'hooks/modal'
import { useAuthentication } from 'hooks/authentication'

export default function Routes() {

    const { modal } = useModal()

    const { isAuthenticated } = useAuthentication()

    if (!isAuthenticated) return (
        <Router>
            <Switch>
                <Route exact path='/a/:token' component={ Token } />
                <Route path='/' component={ Auth } />
            </Switch>
        </Router>
    )

    return (
        <Router>
            <Switch>
                <Route exact path='/a/:token' component={ Token } />
                <Route exact path='/' component={ Dashboard } />
                <Route exact path='/appointment/:appointmentId' component={ Appointment } />
                <Route path='/appointment/:appointmentId/estimate' render={ () => <Appointment display='ESTIMATE' /> } />
                <Route path='/appointment/:appointmentId/invoice' render={ () => <Appointment display='INVOICE' /> } />
                <Route path='/appointment/:appointmentId/thank-you' render={ () => <Appointment display='PAID' /> } />
                <Route path='/appointments' component={ Appointments } />
                <Route path='/vehicles' component={ Vehicles } />
            </Switch>
            { modal.type === 'APPOINTMENT' && <Appointment /> }
        </Router>
    )

}