// import React from 'react'
import moment from 'moment'

export function FormatDate({ date, format, children }) {

    if (!date && !children) return null

    return moment.unix((date || children).substr(0,10)).format(format || 'MMMM Do YYYY, h:mm a')

}

export function FormatPhone({ children = '' }) {
    if (!children) return null
    return children.toString().replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
}

export function FormatCurrency({ children }) {
    const formattedNumber = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(children || 0).replace(/[A-Z$]/g, "")
    return formattedNumber
}