import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import validator from 'validator'

import { useForm } from './FormProvider'
import FieldContainer from './FieldContainer'

import './field.scss'

const INPUT_TYPES = {
    text: 'text',
    tel: 'text',
    email: 'text',
    select: 'select',
    textarea: null,
    password: 'password',
    decimal: 'text',
    url: 'text',
    checkbox: 'checkbox',
}

const INPUT_MODES = {
    text: 'text',
    tel: 'tel',
    email: 'email',
    select: null,
    textarea: 'text',
    password: 'text',
    decimal: 'decimal',
    url: 'url',
    checkbox: 'text'
}

const FORMATTING = {
    text: (value) => value,
    textarea: (value) => value,
    select: (value) => value,
    tel: (value) => formatPhone(value),
    password: (value) => value,
    decimal: (value) => (value || '').toString().replace(/[^0-9.]/g, ''),
    url: (value) => value,
    email: (value) => value,
    uppercase: (value) => (value || '').toUpperCase(),
    alpha: (value) => (value || '').replace(/[^A-Za-z]/g, ''),
    checkbox: (value) => value,
}

const RAW_FORMATTING = {
    text: (value) => value,
    textarea: (value) => value,
    select: (value) => value,
    tel: (value) => (value || '').replace(/\D/g, ''),
    password: (value) => value,
    decimal: (value) => (value || '').toString().replace(/[^0-9.]/g, ''),
    url: (value) => value,
    email: (value) => value,
    uppercase: (value) => (value || '').toUpperCase(),
    alpha: (value) => (value || '').replace(/[^A-Za-z]/g, ''),
    checkbox: (value) => value,
}

// const VALIDATIONS = {
//     text: () => true,
//     email: (value) => {
//         validationError(validator.isEmail(value), 'Please enter a valid email address.')
//     }
// }   

// function validationError(validation, errorMessage) {
//     return {
//         pass: validation,
//         message: validation ? '' : errorMessage
//     }
// }

function formatPhone(value) {
    let phone = value.replace(/\D/g, '');
    const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
        phone = `${ match[2] ? '(' : '' }${match[1]}${ match[2] ? ') ' : '' }${ match[2] }${ match[3] ? '-' : '' }${ match[3] }`;
    }
    return phone
}

export default function Field({ name, id, label, helper = '', type = 'text', defaultValue = '', autoComplete = 'on', format = [], maxLength, onChange, options = [], noBlankOption = false, validate = {}, required }) {

    const [isFocused, setFocus] = useState(false)
    const { inputs, setInput, unsetInput } = useForm()

    const hasId = id > -1

    // On init, set object name
    useEffect(() => {
        if (!inputs[name] || (hasId && !inputs[name][id])) {

            let value = FORMATTING[type](defaultValue)
            format && format.map(format => value = FORMATTING[format](value))
            let rawValue = RAW_FORMATTING[type](value)
            format && format.map(format => rawValue = RAW_FORMATTING[format](value))

            setInput({
                [name]: {
                    value,
                    rawValue
                }
            }, id)
        }

        return () => {
            unsetInput(name)
        }
        // eslint-disable-next-line
    }, [])
    
    const inputId = `f_${name}${hasId ? `_${id}` : ''}`
    const inputValues = hasId && inputs[name] ? inputs[name][id] : inputs[name]

    let value = inputValues ? inputValues.value : ''
    format && format.map(format => value = FORMATTING[format](value))

    const El = type === 'textarea' ? 'textarea' : 'input'

    return (
        <FieldContainer inputId={ inputId } label={ label } helper={ helper } isFocused={ isFocused } isFilled={ inputValues && inputValues.value ? true : false } isTextarea={ type === 'textarea' } isCheckbox={ type === 'checkbox' } >
            {

                type === 'checkbox' && (
                    <>
                        <input
                            type='checkbox'
                            onFocus={() => setFocus(true) }
                            onBlur={() => setFocus(false)}
                            value={ inputValues ? inputValues.value : '' }
                            onChange={(e) => setInput({
                                [name]: {
                                    value: e.target.checked,
                                    rawValue: e.target.checked
                                }
                            })}
                        />
                        <div className={`checkbox ${ value ? 'checkbox--checked' : 'checkbox--unchecked' }`}>
                            <i className='material-icons'>{ value ? 'check_box' : 'check_box_outline_blank' }</i>
                        </div>
                    </>
                )

            }
            {

                type === 'select' && (
                    <select
                        id={ inputId }
                        onFocus={() => setFocus(true) }
                        onBlur={() => setFocus(false)}
                        value={ inputValues ? inputValues.value : '' }
                        onChange={ e => {
        
                            const value = e.target.value
                            const rawValue = value
            
                            setInput({
                                [name]: {
                                    value,
                                    rawValue
                                }
                            }, id)

                            onChange && onChange({
                                target: {
                                    name,
                                    value,
                                    rawValue
                                }
                            })
            
                        }}
                    >
                        { noBlankOption ? null :<option value=''></option> }
                        {
                            options && options.map((obj, i) => {
        
                                const option = Array.isArray(obj) ? obj[1] : obj
                                const optionValue = Array.isArray(obj) ? obj[0] : obj
        
                                return <option key={i} value={ optionValue }>{ option }</option>
                            })
                        }
                    </select>
                ) 

            }
            {
                
                !['checkbox', 'select'].includes(type) && (
                    <El
                        id={ inputId }
                        type={ INPUT_TYPES[type] }
                        inputMode={ INPUT_MODES[type] }
                        onFocus={() => setFocus(true) }
                        onBlur={() => {
                            setFocus(false)
                        }}
                        value={ value }
                        onChange={ e => {

                            let value = FORMATTING[type](e.target.value)
                            format && format.map(format => value = FORMATTING[format](value))
                            let rawValue = RAW_FORMATTING[type](value)
                            format && format.map(format => rawValue = RAW_FORMATTING[format](value))


                            if (maxLength && rawValue.length > maxLength) {
                                return setInput({
                                    [name]: inputValues
                                })
                            }

                            setInput({
                                [name]: {
                                    value,
                                    rawValue
                                }
                            }, id)

                            onChange && onChange({
                                target: {
                                    name,
                                    value,
                                    rawValue
                                }
                            })

                        }}
                        autoComplete={ autoComplete }

                        rows={ type === 'textarea' ? 10 : null }
                        cols={ type === 'textarea' ? 2 : null }
                    />
                )
            }
        </FieldContainer>
    )

}

Field.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.number,
    type: PropTypes.string,
    label: PropTypes.string,
    helper: PropTypes.string,
    inputMode: PropTypes.string,
    // defaultValue: PropTypes.string,
    autoComplete: PropTypes.string,
    format: PropTypes.array,
    maxLength: PropTypes.number,
    options: PropTypes.array,
    noBlankOption: PropTypes.bool,
    validate: PropTypes.object,
    required: PropTypes.bool,
    onChange: PropTypes.func,
}

// type values: [text, checkbox, email, hidden, number, password, radio, search, tel, time, url, week]
// inputMode values: [none, text (default), decimal, numeric, tel, search, email, url]