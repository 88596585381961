import gql from 'graphql-tag'

export const VERIFY_ACCOUNT = gql`
    mutation VerifyAccount($payload: VerifyCustomerAccountPayload!) {
        verifyAccount: verifyCustomerAccount(payload: $payload) {
            accountExists
            user {
                id
            }
        }
    }
`

export const CREATE_ACCOUNT = gql`
    mutation CreateAccount($payload: CreateCustomerAccountPayload!) {
        createAccount: createCustomerAccount(payload: $payload) {
            id
        }
    }
`

export const VERIFICATION_AUTH = gql`
    mutation VerificationCodeAuth($payload: VerificationCodeAuthPayload!) {
        verificationCodeAuth: customerVerificationCodeAuth(payload: $payload) {
            accessToken
            refreshToken
        }
    }
`

export const REFRESH_TOKEN = gql`
    mutation RefreshToken($payload: RefreshTokenPayload!) {
        refreshToken(payload: $payload) {
            accessToken
        }
    }
`