import React from 'react'
import './card.scss'
import { Link } from 'react-router-dom'

import Label from 'components/Label'

export default function Card({ children, type, className, to, onClick }) {

    const Element = to ? Link : 'div'

    return (
        <div style={{ position: 'relative' }}>
            <Element className={`card ${ type ? 'card--' + type : '' } ${ className ? className : '' }`} to={ to } onClick={ (e) => onClick && onClick(e) }>
                { children }
            </Element>
        </div>
    )

}

export function CardImage({ children }) {

    return (
        <div className='card__image'>
            { children || <i className='material-icons'>directions_car</i> }
        </div>
    )

}

export function CardText({ children }) {
    return (
        <div className='card__text'>
            { children }
        </div>
    )
}

export function CardLabel({ children }) {
    return <div className='card__label'><Label>{ children }</Label></div>
}