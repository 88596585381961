import React from 'react'
// import Nav from 'components/Nav'

export default function Layout({ children, login }) {

    return (
        <div className={`container ${ login ? 'container--login' : 'container--main' }`}>
            { children }
        </div>
    )

}