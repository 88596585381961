import React from 'react'

import './iconLink.scss'

export default function({ href, icon, children, small, size }) {
    return (
        <a href={ href } className={`iconLink ${ small ? 'iconLink--small' : '' } ${ size ? `iconLink--${ size }` : '' }`}>
            <i className='material-icons'>{ icon }</i>
            { !small && children }
        </a>
    )
}