import React, { useState } from 'react'
import { Img } from 'components/Tueri'

import './imageBox.scss'

export default function ImageBox({ images, height }) {

    const [ count, ] = useState(0)

    return (
        <div className='imageBox__container' style={{ height: `${ height ? `${ height }px` : false}` }}>
            {
                images && images.map((image, i) => {
                    if (i !== count) return null
                    const thumbnailOptions = { h: height || 0 }

                    if (image.mimetype === 'application/pdf') {
                        thumbnailOptions.fm = 'jpg'
                    }
                    return (
                        <React.Fragment key={i}>
                            <a className='imageBox__image'
                                href={image.location}
                            >
                                <Img src={ image.location } options={ thumbnailOptions } alt={ 'test' } />
                            </a>
                        </React.Fragment>
                    )
                })
            }

            <div className='imageBox__progressContainer'>
                {
                    images && images.length > 1 && images.map((image, i) => {
                        return <div key={i} className={`imageBox__progress ${ i === count ? 'imageBox__progress--active' : '' }`} />
                    })
                }
            </div>
        </div>
    )

}