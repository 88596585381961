import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { ApolloProvider } from '@apollo/react-hooks'
import client from './Apollo'

import TueriProvider from 'components/Tueri'

// import Auth from './pages/Auth'
import AuthenticationProvider from './hooks/authentication'
import ModalProvider from 'hooks/modal'

import Loader from 'components/Loader'

import * as serviceWorker from './serviceWorker';

import './styles/default.scss'
import Routes from './Routes'

import './i18n'

// import { Settings } from 'luxon'

// Settings.defaultLocale = 'fr-CA'

// function Root() {

//   const { isAuthenticated } = useAuthentication()

//   if (isAuthenticated) return <Routes/>

//   return <Auth />
// }

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={ client }>
      <AuthenticationProvider>
      <Suspense fallback={ <Loader /> }>
        <ModalProvider>
          <TueriProvider replacements={[
            ['https://autoservice.nyc3.cdn.digitaloceanspaces.com/uploads/', 'https://cdn.tueri.io/68719476738/autoservice/'],
            ['https://autoservice.nyc3.digitaloceanspaces.com/uploads/', 'https://cdn.tueri.io/68719476738/autoservice/']
          ]}>
            {/* <Root /> */}
            <Routes />
          </TueriProvider>
        </ModalProvider>
        </Suspense>
      </AuthenticationProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
