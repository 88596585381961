import React from 'react'
import { Link } from 'react-router-dom'
import './button.scss'

export default function Button({ to, submit, children, onClick, className }) {

    if (to) return <Link to={ to } className={`button ${ className }`}>{ children }</Link>

    return (
        <button type={submit ? 'submit' : 'button'} className={`button ${ className }`} onClick={() => onClick && onClick()}>{ children }</button>
    )
}