import React, { useEffect, useState } from 'react'

import base64url from 'base64-url'
import { useMutation } from "@apollo/react-hooks";
import { VERIFY_ACCOUNT } from 'operations/Auth'

import { Redirect, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuthentication } from 'hooks/authentication'

export default function () {

    const { i18n } = useTranslation()

    const { token } = useParams()

    const { isAuthenticated } = useAuthentication()

    const [ userId, setUserId ] = useState()

    const [ verifyAccount ] = useMutation(VERIFY_ACCOUNT)

    const [ lng, phone, appointment ] = token.split('.')

    useEffect(() => {

        async function payload() {

            const lang = base64url.decode(lng)

            i18n.changeLanguage(lang)

            if (!isAuthenticated) {

                const { data } = await verifyAccount({
                    variables: {
                        payload: {
                            mobileNumber: base64url.decode(phone)
                        }
                    }
                })
                setUserId(data.verifyAccount.user.id)
            }
            if (isAuthenticated) {
                setUserId('ALREADY_AUTHENTICATED')
            }

        }

        payload()

        // eslint-disable-next-line
    }, [lng])

    if (!userId) return null

    const redirectPath = appointment ? `/appointment/${ base64url.decode(appointment) }` : '/'

    if (isAuthenticated) return <Redirect to={{ pathname: redirectPath }}/>

    // return history.replace('/', { mobileNumber: base64url.decode(phone) })
    return (
        <Redirect 
            to={{
                pathname: redirectPath,
                state: { mobileNumber: base64url.decode(phone), userId }
            }}
        />
    )

}