import React, { useState } from 'react'

import './appointmentSection.scss'

export default function AppointmentSection({ children, title, isOpen = false }) {

    const [ open, setOpen ] = useState(isOpen)

    return (
        <section className='appointmentSection'>
            <button type='button' className={`appointmentSection__expanderButton ${ open ? 'appointmentSection__expanderButton--open' : 'appointmentSection__expanderButton--closed' }`} onClick={() => setOpen(open => !open)}><h2>{ title }</h2><div><i className='material-icons'>keyboard_arrow_right</i></div></button>
            <div className={`appointmentSection__body ${ open ? 'appointmentSection__body--open' : 'appointmentSection__body--closed' }`}>
                { children }
            </div>
        </section>
    )

}