import React, { useState } from 'react'
import './header.scss'

import { useLocation, useHistory, NavLink } from 'react-router-dom'

import logo from './logo.svg'

import { useAuthentication } from 'hooks/authentication'
import { useApolloClient } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

export default function Header() {

    const location = useLocation()
    const history = useHistory()

    const [expanded, setExpanded] = useState()

    const { t, i18n } = useTranslation()

    return (
        <header className='header'>

            <div style={{ width: '60px' }}>

                {
                    location.pathname !== '/' && (
                        <button 
                            type='button'
                            className='hamburgerButton'
                            onClick={() => history.goBack()}
                        >
                            <i className='material-icons'>arrow_back</i>
                        </button>
                    )
                }

            </div>

            <div className='logo'>
                <img src={logo} alt='NAPA Connect'/>
            </div>

            <button className={`hamburgerMenu ${ expanded ? 'hamburgerMenu--active' : '' }`} onClick={() => setExpanded(expanded => !expanded)} aria-label='Navigation menu'>
                    <span className='hamburgerBox'>
                    <span className='hamburgerInner'></span>
                </span>
            </button>
            
            <nav className={`menu ${ expanded ? 'menu--expanded' : '' }`}>
                <ul>
                    <li><NavLink exact to='/'>{ t('dashboard',`Dashboard`) }</NavLink></li>
                    <li><NavLink to='/appointments'>{ t(`Appointments`) }</NavLink></li>
                    <li><NavLink to='/vehicles'>{ t(`Vehicles`) }</NavLink></li>
                    <li><LogoutButton /></li>
                    <li><button type='button' className='language' onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en')}>{ t(`languageSwitchSmall`, 'FR') }</button></li>
                </ul>
            </nav>

        </header>
    )

}

export function LogoutButton({ ...rest }) {

    const { setAuthenticated } = useAuthentication()
    const history = useHistory()
    const client = useApolloClient()

    return (
        <button type='button' className='button__logout' { ...rest }
            onClick={() => {
                setAuthenticated(false)
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')
                client.clearStore()
                history.replace('/')
            }}
        >Sign out</button>
    )
}