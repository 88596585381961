import React from 'react'

import './loader.scss'

export default function Loader({ card, heading, text, width }) {
    return (
        <div 
            className={`loader ${ card ? 'loader--card' : '' } ${ heading ? 'loader--heading' : '' } ${ text ? 'loader--text' : '' }`}
            style={{ width: width ? `${ width }%` : '100%' }}
        />
    )
}