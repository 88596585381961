import React from 'react'
import Layout from 'components/Layout'
import Header from 'components/Header'
import NoData from 'components/NoData'
import Card, { CardImage, CardText } from 'components/Card'

import { useQuery } from '@apollo/react-hooks'
import { GET_VEHICLES } from 'operations/Vehicle'
// import { useAuthentication } from 'hooks/authentication'

import { Img } from 'components/Tueri'
import Loader from 'components/Loader'

import { useTranslation } from 'react-i18next'

export default function Vehicles() {

    const { t } = useTranslation()

    // const { logout } = useAuthentication()

    const { error, loading, data } = useQuery(GET_VEHICLES)

    if (error || loading) return <Loader />

    return (
        <Layout>
            <Header />

            <div className='containerPadding' style={{ paddingBottom: '60px' }}>

                <div className='constrain'>

                <h1>{ t(`My Vehicles`) }</h1>

                {
                    data.vehicles && data.vehicles.length > 0 ? (
                        data.vehicles.map(vehicle => (
                            <Card key={ vehicle.id } type='vehicle'>
                                <CardImage>
                                    <Img  aspectRatio={50} src={ vehicle.images[0].location } alt={ `${ vehicle.year } ${ vehicle.make } ${ vehicle.model }` } />
                                </CardImage>
                                <CardText>
                                    <h1>{ vehicle.year } { vehicle.make } { vehicle.model }</h1>
                                </CardText>
                            </Card>
                        ))
                    ) : (
                        <NoData
                            buttonText={t(`Add a vehicle`)}
                        >
                            { t(`Looks like you haven't added a vehicle yet.`) }
                        </NoData>
                    )
                }

                </div>

            </div>

            {/* <button type='button' onClick={() => logout()}>{ t(`Logout`) }</button> */}
            
        </Layout>
    )

}