import React from 'react'

import Layout from 'components/Layout'

import Header from 'components/Header'

import { useTranslation } from 'react-i18next'

import { useQuery } from '@apollo/react-hooks'

import { GET_APPOINTMENTS_HISTORY } from 'operations/Appointment'
import Loader from 'components/Loader'

import NoData from 'components/NoData'

import Card, { CardLabel, CardImage } from 'components/Card'

import { Img } from 'components/Tueri'

import { DateTime } from 'luxon'

export default function() {

    const { t } = useTranslation()

    const { error, loading, data } = useQuery(GET_APPOINTMENTS_HISTORY)

    if (error) return 'Error loading appointments history.'

    if (loading) return <Loader />

    const { appointmentsHistory: appointments } = data

    const now = DateTime.local()

    return (
        <Layout>
            <Header/>

            <div className='containerPadding'>

                <div className='constrain'>

                    <h1>{ t(`Appointment history`) }</h1>

                    {
                        appointments.length > 0 ? (
                            appointments.map((appointment, key) => {

                                let tense = 'future'
                                if (appointment.appointmentStartAt && DateTime.fromMillis(Number(appointment.appointmentStartAt)) < now) tense = 'past'
                                if (appointment.appointmentStartAt && DateTime.fromMillis(Number(appointment.appointmentStartAt)) <= now && !['APPOINTMENT_REQUEST', 'DEPARTURE_PICKUP_COMPLETE', 'DEPARTURE_DROP_COMPLETE'].includes(appointment.status)) tense = 'present'
                        
                                let yearTense = tense
                                if (yearTense === 'future' && appointment.appointmentStartAt && DateTime.fromMillis(Number(appointment.appointmentStartAt)).year === now.year) yearTense = 'present'

                                return (
                                    <div key={ appointment.id }>
                                    <Card key={ appointment.id } type='vehicle' className={`appointment__container`} to={`/appointment/${ appointment.id }`}>
                                        <CardLabel>{ appointment.status }</CardLabel>
                                        <div style={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 3fr'
                                        }}>
                                            <div className={`cardDate cardDate--${ tense }`}>
                                                <div>
                                                    { DateTime.fromMillis(Number(appointment.appointmentStartAt)).toFormat('MMM') }<br/>
                                                    { DateTime.fromMillis(Number(appointment.appointmentStartAt)).toFormat('d') }
                                                </div>
                                            </div>
                                            <CardImage>
                                                { appointment.vehicle.images && appointment.vehicle.images[0] && <Img aspectRatio={50} src={ appointment.vehicle.images[0].location } alt={ `${ appointment.vehicle.year } ${ appointment.vehicle.make } ${ appointment.vehicle.model }` } /> }
                                                <div className='cardImage_overlay'>
                                                    <div>
                                                        <div className='appointment__vehicle'>{ appointment.vehicle.year } { appointment.vehicle.make } { appointment.vehicle.model }</div>
                                                    </div>
                                                </div>
                                            </CardImage>
                                        </div>
                                    </Card>

                                        {
                                            (!appointments[key + 1] || DateTime.fromMillis(Number(appointment.appointmentStartAt)).year !== DateTime.fromMillis(Number(appointments[key + 1].appointmentStartAt)).year) && (
                                                <>
                                                    <div className={`timeline timeline--${tense}`}/>
                                                    <div className={`timelineYear timelineYear--${ yearTense }`}><div className='timelineYearLine' /><div className={`year`}>{ appointment.appointmentStartAt ? DateTime.fromMillis(Number(appointment.appointmentStartAt)).year : 'N/A' }</div></div>
                                                    { appointments[key + 1] && <div className={`timeline timeline--${ yearTense }`}/> }
                                                </>
                                            )
                                        }
                        
                                        <div className={`timeline timeline--${tense}`}/>

                                    </div>
                                )
                            })
                        ) : (
                            <NoData>
                                { t(`Looks like you don't have an appointment yet.`) }
                            </NoData>
                        )
                    }

                </div>

            </div>

        </Layout>
    )

}