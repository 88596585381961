import React, { useState } from 'react'
import Form, { Field, Submit } from 'components/Form'
import { useMutation } from '@apollo/react-hooks'
import { VERIFY_ACCOUNT, CREATE_ACCOUNT, VERIFICATION_AUTH } from 'operations/Auth';
// import { useAuthentication } from 'hooks/authentication'

import Layout from 'components/Layout'

// import AccessCode from 'components/AccessCode'
import AccessCode from 'components/AccessCode2'

// import logo from 'assets/logoOnly_en.png'
import { FormatPhone } from 'components/Format';

// import HCaptcha from '@hcaptcha/react-hcaptcha'
import Loader from 'components/Loader';

import { useTranslation, Trans } from 'react-i18next'
import { useLocation } from 'react-router-dom';

export default function Auth() {

	const { t, i18n } = useTranslation()

	const location = useLocation()

	// STEPS: VERIFICATION_CHECK, CREATE_ACCOUNT, VERIFICATION_CODE
	const [ step, setStep ] = useState( location && location.state && location.state.mobileNumber ? 'VERIFICATION_CODE' : 'VERIFICATION_CHECK')
	const [ userId, setUserId ] = useState(location && location.state && location.state.userId ? location.state.userId : null)
	const [ mobileNumber, setMobileNumber ] = useState(location && location.state && location.state.mobileNumber ? location.state.mobileNumber : null)
	// const [ captchaToken, setCaptchaToken ] = useState()

	// const { setAuthenticated } = useAuthentication()

	const [ verifyAccount, { loading: verifyAccountLoading } ] = useMutation(VERIFY_ACCOUNT)
	const [ createAccount, { loading: createAccountLoading } ] = useMutation(CREATE_ACCOUNT)
	const [ , { loading: verificationAuthLoading } ] = useMutation(VERIFICATION_AUTH)

	// const captcharef = useRef(null)

	console.log(location)

	return (
		<Layout login>
			{/* <img className='logo' src={ logo } alt='NAPA Service' /> */}
			
			<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				left: '4rem',
				right: '4rem',
				height: '100%'
			}}>
			<Form
				className='login'
				onSubmit={async ({ rawInputs: inputs, enableSubmit }) => {

					// if (!captchaToken) {
					// 	enableSubmit()
					// 	return null
					// }

					try {

						if (step === 'VERIFICATION_CHECK') {

							setMobileNumber(inputs.mobileNumber)

							const { data } = await verifyAccount({
								variables: {
									payload: {
										mobileNumber: inputs.mobileNumber,
										// captchaToken
									}
								}
							})

							enableSubmit()
							
							if (!data.verifyAccount.accountExists) {
								return setStep('CREATE_ACCOUNT')
							}

							setUserId(data.verifyAccount.user.id)

							setStep('VERIFICATION_CODE')

						}


						if (step === 'CREATE_ACCOUNT') {

							const { data } = await createAccount({
								variables: {
									payload: {
										fullName: inputs.fullName,
										mobileNumber: inputs.mobileNumber
									}
								}
							})

							enableSubmit()

							setUserId(data.createAccount.id)

							setStep('VERIFICATION_CODE')

						}

						// if (step === 'VERIFICATION_CODE') {

						// 	const { data } = await verificationAuth({
						// 		variables: {
						// 			payload: {
						// 				id: userId,
						// 				verificationCode: inputs.verificationCode
						// 			}
						// 		}
						// 	})

						// 	const { verificationCodeAuth } = data

						// 	localStorage.setItem('accessToken', verificationCodeAuth.accessToken)
						// 	localStorage.setItem('refreshToken', verificationCodeAuth.refreshToken)
						// 	setAuthenticated(true)

						// }

					} catch (err) {
						// captcharef && captcharef.current && captcharef.current.resetCaptcha()
					}

				}}>
					{({ rawInputs }) => {
						return (
							<div style={{ minWidth: '320px' }}>
								
								{ (verifyAccountLoading || createAccountLoading || verificationAuthLoading) && <Loader /> }

								{ step === 'VERIFICATION_CHECK' && (
									<>
										<i className='material-icons logo'>directions_car</i>
										<h1>{ t(`Sign in or sign up`) }</h1>
										<Field name='mobileNumber' type='tel' autoComplete='off' label={ t('Mobile Phone Number') } /> 
										{/* <HCaptcha ref={captcharef} sitekey="107a5e02-3a23-4fdd-ae44-f1304a072e1a" onVerify={ token => setCaptchaToken(token) }/> */}
									</>
								)}
								{ step === 'CREATE_ACCOUNT' && <Field name='fullName' label={ t('Full Name') } autoComplete='off'/> }
								{/* { step === 'VERIFICATION_CODE' && <Input name='verificationCode' inputMode='numeric' autoComplete='off' label='Verification Code'/> } */}
								{ step === 'VERIFICATION_CODE' && (
									<>
										<i className='material-icons logo'>directions_car</i>
										<h1><Trans >We sent a verification code to<br/><strong><FormatPhone>{{ mobileNumber }}</FormatPhone></strong></Trans></h1>
										<AccessCode userId={ userId } /> 
									</>
								)}

								{/* { step !== 'VERIFICATION_CODE' && captchaToken && <Submit fullWidth >Continue</Submit> } */}
								{ step !== 'VERIFICATION_CODE' && <Submit fullWidth>{ t(`Continue`) }</Submit> }

								<button type='button' className='languageSwitcher' onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en')}>{ t('languageSwitcher', 'Français') }</button>
							</div>
						)
					}}
			</Form>
			</div>
		</Layout>
	)
}